@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500&family=Montserrat:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500&family=M+PLUS+Rounded+1c&family=Montserrat:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
}



button{
    height: 50px;
    border: 3px solid #A8A4FE;
    background: #A8A4FE;
    color: white;
    font-family: 'Zilap';
    box-shadow: 0px 2px 0px 0px #7E7BBF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

button:hover{
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    box-shadow: 0px 14px 0px 0px #7E7BBF;
}
button:active{
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    box-shadow: 0px 6px 0px 0px #7E7BBF;
}


a{
    height: 50px;
    background: #A8A4FE;
    color: white;
    font-family: 'Zilap';
    cursor: pointer;
}



@media screen and (max-width: 500px) {
}

@-webkit-keyframes hover {
    0%{-webkit-transform: translateY(0);transform: translateY(0);}
    33%{-webkit-transform: translateY(-50px);transform: translateY(-50px);}
    66%{-webkit-transform: translateY(50px);transform: translateY(50px);}
    0%{-webkit-transform: translateY(0);transform: translateY(0);}
}

@keyframes hover {
    0%{-webkit-transform: translateY(0);transform: translateY(0);}
    33%{-webkit-transform: translateY(-50px);transform: translateY(-50px);}
    66%{-webkit-transform: translateY(50px);transform: translateY(50px);}
    0%{-webkit-transform: translateY(0);transform: translateY(0);}
}
.about{
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px 50px;
}
.book {
  width: 100%;
  height: 100%;
  min-width: 950px;
  max-width: 950px;
  background: #835840;
  border: 3px solid #835840;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 5px 16px;
  position: relative;
  z-index: 1;
  border-radius: 3px;
}

.page1 {
  width: 50%;
  height: 100%;
  background: #e2d6e3;
  margin-right: 10px;
  border-radius: 3px;
  position: relative;
  padding: 12px 32px 12px 12px;
}

.page1:before {
  content: "";
  width: 95%;
  height: 6px;
  position: absolute;
  top: -6px;
  left: 12px;
  background: #b49993;
  z-index: -1;
}
.page1:after {
  content: "";
  width: 95%;
  height: 6px;
  position: absolute;
  bottom: -6px;
  left: 12px;
  background: #b49993;
  z-index: -1;
}

.page-1-top {
  display: flex;
  height: 60%;
  padding-top: 16px;
}

.image {
  background: white;
  padding: 12px 12px 40px;
  margin: 4px;
  border-radius: 2px;
}

.img1 {
  width: 200px;
  height: 180px;
}

.img2 {
  margin-top: 24px;
  width: 180px;
  height: 200px;
}

.photo {
  width: 100%;
  height: 100%;
  background: rgb(22, 22, 22);
}

.page-1-bottom {
  display: flex;
  flex-direction: column;
  padding: 0 32px 0;
}

.about-me {
  background: #f1d2c2;
  width: 100%;
  height: 120px;
  padding: 5px;
}

.about-me h3 {
  font-family: "Montserrat", sans-serif;
  font-family: "Caveat", cursive;
  width: 100%;
  font-size: 28px;
  color: #361940;
  display: flex;
  align-items: center;
  text-decoration: underline;
  -webkit-text-decoration-color: #de6583;
          text-decoration-color: #de6583;
}

.about-me p {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 15px;
  line-height: 18px;
  margin: 2px auto;
  font-weight: 800;
  color: #3f2d34;
}

.about-me .blue-square {
  width: 8px;
  height: 8px;
  background: #768ac4;
  display: inline-block;
  margin: 0 4px;
}

.highlight {
  display: inline-block;
  height: 100%;
  word-wrap: break-word;
}

.h-blue {
  background: #b6b0c4;
}

.h-purple {
  background: #b080bd;
}

.h-green {
  background: #d4de8f;
}

.chevron-tab {
  content: "";
  width: 43px;
  height: 54px;
  background: #f7939b;
  position: absolute;
  left: 34px;
  top: -34px;
  z-index: -1;
  border-radius: 3px;
  overflow: hidden;
}

.green-tab {
  content: "";
  width: 32px;
  height: 54px;
  background: #a4b766;
  position: absolute;
  left: 174px;
  top: -40px;
  z-index: -1;
  border-radius: 3px;
  overflow: hidden;
}

.stripe-tab {
  content: "";
  width: 65px;
  height: 80px;
  background: white;
  position: absolute;
  left: 93px;
  top: -26px;
  z-index: 1;
  border-radius: 3px;
  overflow: hidden;
}

.stripe-container {
  width: 300%;
  height: 130%;
  -webkit-transform: rotate(-45deg) translate(-0%, -52%);
          transform: rotate(-45deg) translate(-0%, -52%);
  display: flex;
  flex-direction: column;
}

.stripe-pink {
  display: block;
  background: #de6583;
  height: 50px;
  width: 100%;
}

.stripe-peach {
  display: block;
  background: #f5b1a9;
  height: 50px;
  width: 100%;
}

.page2 {
  width: 50%;
  height: 100%;
  background: #e2d6e3;
  margin-left: 10px;
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 22px;
}

.page2:before {
  content: "";
  width: 95%;
  height: 6px;
  position: absolute;
  top: -6px;
  right: 12px;
  background: #b49993;
  z-index: -1;
}
.page2:after {
  content: "";
  width: 95%;
  height: 6px;
  position: absolute;
  bottom: -6px;
  right: 12px;
  background: #b49993;
  z-index: -1;
}

.purple-tab {
  content: "";
  width: 38px;
  height: 38px;
  background: #945aa4;
  position: absolute;
  right: 14px;
  top: -36px;
  z-index: -1;
  border-radius: 3px;
  overflow: hidden;
}

.text-columns {
  width: 100%;
  height: calc(45% - 16px);
  padding-top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-column {
  width: 50%;
  height: 100%;
  background: transparent;
  margin: 0 16px;
}

.title {
  width: 50%;
  border: 2px solid #5b3551;
  margin: 16px 0;
  border-radius: 3px;
}

.info-grid {
  width: 100%;
  height: 55%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
}

.grid {
  width: calc(50% - 8px);
  height: calc(50% - 8px);
  margin-bottom: 8px;
  border-radius: 3px;
}
.gr1 {
  background: #9bc87e;
  margin-right: 8px;
}
.gr2 {
  background: #b080bd;
  width: 50%;
}
.gr3 {
  background: #f2be65;
  width: calc(30% - 8px);
  margin-right: 8px;
}
.gr4 {
  background: #8fa0d0;
  width: 70%;
}

.spiral {
  width: 60px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 999;
  padding: 70px 0;
}

.spiral .ring {
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background: #4b203d;
  position: relative;
}

.spiral .ring:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #b49993;
  position: absolute;
  left: 2px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
}
.spiral .ring:after {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #b49993;
  position: absolute;
  right: 2px;
  top: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  z-index: -1;
}

.contact{
    position: relative;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 100px 250px;
    transition: all .25s ease;
}

form{
    width: 320px;
    margin: 10px auto;
    border-top: 2px solid white;
    padding-top: 32px;
}

.input{
    margin-bottom: 30px;
}

input, textarea, button{
    width: 320px;
    height: 30px;
    border: 3px solid #A8A4FE;
    border-radius: 3px;
    padding: 6px 6px 2px;
    margin: 0 auto;
    position: relative;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    letter-spacing: 1px;
    resize: none;
    overflow: hidden;
    transition: all .125s ease;
}

button{
    height: 50px;
}

textarea{
    height: 100px;
}

input:focus, textarea:focus{
    outline: 1px solid #F9FE6E;
}
label{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 8px 2px;
    width: auto;
    height: 30px;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    color: black;
    cursor: text;
    transition: all .125s linear;
    font-family: 'Zilap';
}
textarea + label {
    height: 30px;
    -webkit-transform: translateY(-105px);
            transform: translateY(-105px);
}

input:focus{
    outline: 1px solid #F9FE6E;
}
input:focus + label{
    -webkit-transform: translateY(calc(-100% - 25px));
            transform: translateY(calc(-100% - 25px));
    font-size: .8rem;
}
textarea:focus + label{
    -webkit-transform: translateY(calc(-345% - 25px));
            transform: translateY(calc(-345% - 25px));
    font-size: .8rem;
}

input + label.stick{
    -webkit-transform: translateY(calc(-100% - 25px));
            transform: translateY(calc(-100% - 25px));
    font-size: .8rem;
}

textarea + label.stick{
    -webkit-transform: translateY(calc(-345% - 25px));
            transform: translateY(calc(-345% - 25px));
    font-size: .8rem;
}

.green{
    color: #6EFEBB;
}
.red{
    color: #FE736E;
}

.green-border{
    border: 3px solid #60DEA4;
}
.red-border{
    border: 3px solid #DE6560;
}

.message-sent{
    font-family: 'Zilap';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.message-sent p{
    font-family: 'Lato', sans-serif;
}




button:disabled{
    border: 3px solid gray;
    background: gray;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgb(58, 58, 58);
}
button:disabled:hover{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    color: white;
    box-shadow: 0 0 0 0 rgb(58, 58, 58);
}

.info{
    width: 320px;
    font-size: 18px;
    font-weight: 900;
    text-align: justify;
}

.info a{
    min-width: 320px;
    text-decoration: none;
    padding: 8px 4px;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .125s ease;
    margin: 32px 0;
}

.info h1{
    min-width: 320px;
    font-size: 4rem;
    font-family: 'Zilap';
}

.info p{
    min-width: 320px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding: 12px 0;
}

.discordIcon{
    width: 18px;
    height: 18px;
    margin-right: 4px;
}
a:hover{
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}
a:active{
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
}

.discord{
    background: #5865f2;
}
.discord:hover{
    box-shadow: 0px 14px 0px 0px #23272a;
}
.discord:active{
    box-shadow: 0px 6px 0px 0px #23272a;
}

.tiktok{
    background: #fe2c55;
}
.tiktok:hover {
    box-shadow: 0px 14px 0px 0px #BF2140;
}
.tiktok:active{
    box-shadow: 0px 6px 0px 0px #BF2140;
}

.twitch{
    background: #9147ff;
}
.twitch:hover {
    box-shadow: 0px 14px 0px 0px #772ce8;
}
.twitch:active{
    box-shadow: 0px 6px 0px 0px #772ce8;
}

@media screen and (max-width: 1200px) {
    .contact{
        padding: 100px 0px;
    }
}

@media screen and (max-width: 700px) {
    
}
@font-face {
  font-family: "Zilap";
  src: local("Zilap"), url(/static/media/ZilapMinimalist.a599cdaf.ttf) format("truetype");
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: hidden;
  font-family: "Lato", sans-serif;
}

:root {
  --red: #dd5e89;
  --orange: #ddb25e;
  --yellow: #c9dd5e;
  --green: #5edd73;
  --blue: #5ec8dd;
  --purple: #b25edd;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

.container {
  position: absolute;
  left: 0;
  width: 300vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  transition: all 0.25s ease-in-out;
  background: #dd5e89;
  background: linear-gradient(to right, #f7bb97, #dd5e89);
}

.butterfly {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 9999;
  font-size: 5rem;
  font-weight: 400;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -10px;
  color: white;
  -webkit-animation: flap-around 45s linear infinite;
          animation: flap-around 45s linear infinite;
}

.splash {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.bubble {
  position: absolute;
  left: calc(25% - 350px);
  bottom: 15%;
  z-index: -1;
  transition: all 0.25s ease;
  -webkit-animation: hover 45s ease-in-out infinite;
          animation: hover 45s ease-in-out infinite;
}
.b1 {
  font-size: 50rem;
  left: calc(35% - 350px);
  -webkit-animation: hover 45s ease-in-out infinite;
          animation: hover 45s ease-in-out infinite;
}
.b2 {
  font-size: 30rem;
  left: calc(35% + 50px);
  -webkit-animation: hover 25s ease-in-out infinite;
          animation: hover 25s ease-in-out infinite;
}
.b3 {
  font-size: 30rem;
  left: calc(85% - 50px);
  bottom: 80%;
  -webkit-animation: hover 34s ease-in-out infinite;
          animation: hover 34s ease-in-out infinite;
}
.b4 {
  font-size: 45rem;
  left: 60%;
  bottom: -25%;
  -webkit-animation: hover 50s ease-in-out infinite;
          animation: hover 50s ease-in-out infinite;
}
.b5 {
  font-size: 25rem;
  left: 1%;
  bottom: 0%;
  -webkit-animation: hover 25s ease-in-out infinite;
          animation: hover 25s ease-in-out infinite;
}

.wing {
  display: block;
  -webkit-animation: flap 1s ease-in-out infinite;
          animation: flap 1s ease-in-out infinite;
  font-size: 6rem;
}
.left-wing {
  -webkit-transform-origin: calc(100% + 6px);
          transform-origin: calc(100% + 6px);
}
.right-wing {
  -webkit-transform-origin: 4px;
          transform-origin: 4px;
}

.loading {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  -webkit-animation: loading 2s linear infinite;
          animation: loading 2s linear infinite;
  border: 3px solid #dd5e89;
  border: 3px solid var(--red);
  position: relative;
  margin-top: -4px;
}
.loading-complete {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  -webkit-animation: loading-complete 2s linear infinite;
          animation: loading-complete 2s linear infinite;
  position: relative;
}
.checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 30px;
  -webkit-animation: reveal-check 2s ease 1;
          animation: reveal-check 2s ease 1;
  stroke-dasharray: 25;
  stroke-dashoffset: 50;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #dd5e89;
    border: 3px solid var(--red);
    border-left-color: transparent;
    color: #dd5e89;
    color: var(--red);
  }
  10% {
    -webkit-transform: rotate(36deg);
            transform: rotate(36deg);
  }
  20% {
    -webkit-transform: rotate(72deg);
            transform: rotate(72deg);
    border: 3px solid #ddb25e;
    border: 3px solid var(--orange);
    border-left-color: transparent;
    color: #ddb25e;
    color: var(--orange);
  }
  30% {
    -webkit-transform: rotate(108deg);
            transform: rotate(108deg);
  }
  40% {
    -webkit-transform: rotate(144deg);
            transform: rotate(144deg);
    border: 3px solid #c9dd5e;
    border: 3px solid var(--yellow);
    border-left-color: transparent;
    color: #c9dd5e;
    color: var(--yellow);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  60% {
    -webkit-transform: rotate(216deg);
            transform: rotate(216deg);
    border: 3px solid #5edd73;
    border: 3px solid var(--green);
    border-left-color: transparent;
    color: #5edd73;
    color: var(--green);
  }
  70% {
    -webkit-transform: rotate(252deg);
            transform: rotate(252deg);
  }
  80% {
    -webkit-transform: rotate(288deg);
            transform: rotate(288deg);
    border: 3px solid #5ec8dd;
    border: 3px solid var(--blue);
    border-left-color: transparent;
    color: #5ec8dd;
    color: var(--blue);
  }
  90% {
    -webkit-transform: rotate(324deg);
            transform: rotate(324deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    border: 3px solid #dd5e89;
    border: 3px solid var(--red);
    border-left-color: transparent;
    color: #dd5e89;
    color: var(--red);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #dd5e89;
    border: 3px solid var(--red);
    border-left-color: transparent;
    color: #dd5e89;
    color: var(--red);
  }
  10% {
    -webkit-transform: rotate(36deg);
            transform: rotate(36deg);
  }
  20% {
    -webkit-transform: rotate(72deg);
            transform: rotate(72deg);
    border: 3px solid #ddb25e;
    border: 3px solid var(--orange);
    border-left-color: transparent;
    color: #ddb25e;
    color: var(--orange);
  }
  30% {
    -webkit-transform: rotate(108deg);
            transform: rotate(108deg);
  }
  40% {
    -webkit-transform: rotate(144deg);
            transform: rotate(144deg);
    border: 3px solid #c9dd5e;
    border: 3px solid var(--yellow);
    border-left-color: transparent;
    color: #c9dd5e;
    color: var(--yellow);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  60% {
    -webkit-transform: rotate(216deg);
            transform: rotate(216deg);
    border: 3px solid #5edd73;
    border: 3px solid var(--green);
    border-left-color: transparent;
    color: #5edd73;
    color: var(--green);
  }
  70% {
    -webkit-transform: rotate(252deg);
            transform: rotate(252deg);
  }
  80% {
    -webkit-transform: rotate(288deg);
            transform: rotate(288deg);
    border: 3px solid #5ec8dd;
    border: 3px solid var(--blue);
    border-left-color: transparent;
    color: #5ec8dd;
    color: var(--blue);
  }
  90% {
    -webkit-transform: rotate(324deg);
            transform: rotate(324deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    border: 3px solid #dd5e89;
    border: 3px solid var(--red);
    border-left-color: transparent;
    color: #dd5e89;
    color: var(--red);
  }
}

@-webkit-keyframes loading-complete {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #dd5e89;
    border: 3px solid var(--red);
    color: #dd5e89;
    color: var(--red);
  }
  10% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #ddb25e;
    border: 3px solid var(--orange);
    color: #ddb25e;
    color: var(--orange);
  }
  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #c9dd5e;
    border: 3px solid var(--yellow);
    color: #c9dd5e;
    color: var(--yellow);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #5edd73;
    border: 3px solid var(--green);
    color: #5edd73;
    color: var(--green);
  }
  70% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #5ec8dd;
    border: 3px solid var(--blue);
    color: #5ec8dd;
    color: var(--blue);
  }
  90% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #dd5e89;
    border: 3px solid var(--red);
    color: #dd5e89;
    color: var(--red);
  }
}

@keyframes loading-complete {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #dd5e89;
    border: 3px solid var(--red);
    color: #dd5e89;
    color: var(--red);
  }
  10% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #ddb25e;
    border: 3px solid var(--orange);
    color: #ddb25e;
    color: var(--orange);
  }
  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #c9dd5e;
    border: 3px solid var(--yellow);
    color: #c9dd5e;
    color: var(--yellow);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #5edd73;
    border: 3px solid var(--green);
    color: #5edd73;
    color: var(--green);
  }
  70% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #5ec8dd;
    border: 3px solid var(--blue);
    color: #5ec8dd;
    color: var(--blue);
  }
  90% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border: 3px solid #dd5e89;
    border: 3px solid var(--red);
    color: #dd5e89;
    color: var(--red);
  }
}

@-webkit-keyframes reveal-check {
  0% {
    stroke-dashoffset: 25;
  }
  50% {
    stroke-dashoffset: 50;
  }
  100% {
    stroke-dashoffset: 50;
  }
}

@keyframes reveal-check {
  0% {
    stroke-dashoffset: 25;
  }
  50% {
    stroke-dashoffset: 50;
  }
  100% {
    stroke-dashoffset: 50;
  }
}

@media screen and (max-width: 768px) {
  .b1 {
    font-size: 40rem;
    left: calc(35% - 350px);
    -webkit-animation: hover 45s ease-in-out infinite;
            animation: hover 45s ease-in-out infinite;
  }
  .b2 {
    font-size: 20rem;
    left: calc(35% + 50px);
    -webkit-animation: hover 25s ease-in-out infinite;
            animation: hover 25s ease-in-out infinite;
  }
  .b3 {
    font-size: 20rem;
    left: calc(85% - 50px);
    bottom: 80%;
    -webkit-animation: hover 34s ease-in-out infinite;
            animation: hover 34s ease-in-out infinite;
  }
  .b4 {
    font-size: 35rem;
    left: 60%;
    bottom: -25%;
    -webkit-animation: hover 50s ease-in-out infinite;
            animation: hover 50s ease-in-out infinite;
  }
  .b5 {
    font-size: 15rem;
    left: 1%;
    bottom: 0%;
    -webkit-animation: hover 25s ease-in-out infinite;
            animation: hover 25s ease-in-out infinite;
  }
}

@-webkit-keyframes flap {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0turn);
            transform: rotate3d(0, 1, 0, 0turn);
  }
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -0.2turn);
            transform: rotate3d(0, 1, 0, -0.2turn);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0turn);
            transform: rotate3d(0, 1, 0, 0turn);
  }
}

@keyframes flap {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0turn);
            transform: rotate3d(0, 1, 0, 0turn);
  }
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -0.2turn);
            transform: rotate3d(0, 1, 0, -0.2turn);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0turn);
            transform: rotate3d(0, 1, 0, 0turn);
  }
}

@-webkit-keyframes flap-around {
  0% {
    -webkit-transform: translate(-1500%, 500%) rotate(60deg);
            transform: translate(-1500%, 500%) rotate(60deg);
  }
  17% {
    -webkit-transform: translate(-90%, 30%) rotate(30deg);
            transform: translate(-90%, 30%) rotate(30deg);
  }
  23% {
    -webkit-transform: translate(-90%, 30%) rotate(30deg);
            transform: translate(-90%, 30%) rotate(30deg);
  }
  40% {
    -webkit-transform: translate(1500%, -500%) rotate(90deg);
            transform: translate(1500%, -500%) rotate(90deg);
  }
  45% {
    -webkit-transform: translate(1900%, -250%) rotate(140deg);
            transform: translate(1900%, -250%) rotate(140deg);
  }
  47.5% {
    -webkit-transform: translate(2200%, -125%) rotate(165deg);
            transform: translate(2200%, -125%) rotate(165deg);
  }
  50% {
    -webkit-transform: translate(2300%, 0%) rotate(190deg);
            transform: translate(2300%, 0%) rotate(190deg);
  }
  52.5% {
    -webkit-transform: translate(2200%, 125%) rotate(215deg);
            transform: translate(2200%, 125%) rotate(215deg);
  }
  55% {
    -webkit-transform: translate(1900%, 250%) rotate(240deg);
            transform: translate(1900%, 250%) rotate(240deg);
  }
  60% {
    -webkit-transform: translate(1500%, 500%) rotate(300deg);
            transform: translate(1500%, 500%) rotate(300deg);
  }
  80% {
    -webkit-transform: translate(60%, -60%) rotate(295deg);
            transform: translate(60%, -60%) rotate(295deg);
  }
  100% {
    -webkit-transform: translate(-1500%, -500%) rotate(290deg);
            transform: translate(-1500%, -500%) rotate(290deg);
  }
}

@keyframes flap-around {
  0% {
    -webkit-transform: translate(-1500%, 500%) rotate(60deg);
            transform: translate(-1500%, 500%) rotate(60deg);
  }
  17% {
    -webkit-transform: translate(-90%, 30%) rotate(30deg);
            transform: translate(-90%, 30%) rotate(30deg);
  }
  23% {
    -webkit-transform: translate(-90%, 30%) rotate(30deg);
            transform: translate(-90%, 30%) rotate(30deg);
  }
  40% {
    -webkit-transform: translate(1500%, -500%) rotate(90deg);
            transform: translate(1500%, -500%) rotate(90deg);
  }
  45% {
    -webkit-transform: translate(1900%, -250%) rotate(140deg);
            transform: translate(1900%, -250%) rotate(140deg);
  }
  47.5% {
    -webkit-transform: translate(2200%, -125%) rotate(165deg);
            transform: translate(2200%, -125%) rotate(165deg);
  }
  50% {
    -webkit-transform: translate(2300%, 0%) rotate(190deg);
            transform: translate(2300%, 0%) rotate(190deg);
  }
  52.5% {
    -webkit-transform: translate(2200%, 125%) rotate(215deg);
            transform: translate(2200%, 125%) rotate(215deg);
  }
  55% {
    -webkit-transform: translate(1900%, 250%) rotate(240deg);
            transform: translate(1900%, 250%) rotate(240deg);
  }
  60% {
    -webkit-transform: translate(1500%, 500%) rotate(300deg);
            transform: translate(1500%, 500%) rotate(300deg);
  }
  80% {
    -webkit-transform: translate(60%, -60%) rotate(295deg);
            transform: translate(60%, -60%) rotate(295deg);
  }
  100% {
    -webkit-transform: translate(-1500%, -500%) rotate(290deg);
            transform: translate(-1500%, -500%) rotate(290deg);
  }
}


.nav{
    position: fixed;
    left: 50%;
    top: 50px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    justify-content: space-evenly;
    background: #D5A4FE;
    border-radius: 30px;
}

.nav button{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 20px;
    margin: 4px;
    padding: 2px 0 0;
    background: transparent;
    z-index: 10000;
    cursor: pointer;
    font-family: 'Zilap';
    color: white;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    box-shadow: 0 0 0 0 #000000;
}
.nav button:hover{
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.nav-highlight{
    content: '';
    width: 100px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #A8A4FE;
    border-radius: 20px;
    transition: all .25s ease;
}
