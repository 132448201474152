@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500&family=Montserrat:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500&family=M+PLUS+Rounded+1c&family=Montserrat:wght@300;400&display=swap");

.book {
  width: 100%;
  height: 100%;
  min-width: 950px;
  max-width: 950px;
  background: #835840;
  border: 3px solid #835840;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 5px 16px;
  position: relative;
  z-index: 1;
  border-radius: 3px;
}

.page1 {
  width: 50%;
  height: 100%;
  background: #e2d6e3;
  margin-right: 10px;
  border-radius: 3px;
  position: relative;
  padding: 12px 32px 12px 12px;
}

.page1:before {
  content: "";
  width: 95%;
  height: 6px;
  position: absolute;
  top: -6px;
  left: 12px;
  background: #b49993;
  z-index: -1;
}
.page1:after {
  content: "";
  width: 95%;
  height: 6px;
  position: absolute;
  bottom: -6px;
  left: 12px;
  background: #b49993;
  z-index: -1;
}

.page-1-top {
  display: flex;
  height: 60%;
  padding-top: 16px;
}

.image {
  background: white;
  padding: 12px 12px 40px;
  margin: 4px;
  border-radius: 2px;
}

.img1 {
  width: 200px;
  height: 180px;
}

.img2 {
  margin-top: 24px;
  width: 180px;
  height: 200px;
}

.photo {
  width: 100%;
  height: 100%;
  background: rgb(22, 22, 22);
}

.page-1-bottom {
  display: flex;
  flex-direction: column;
  padding: 0 32px 0;
}

.about-me {
  background: #f1d2c2;
  width: 100%;
  height: 120px;
  padding: 5px;
}

.about-me h3 {
  font-family: "Montserrat", sans-serif;
  font-family: "Caveat", cursive;
  width: 100%;
  font-size: 28px;
  color: #361940;
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-decoration-color: #de6583;
}

.about-me p {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 15px;
  line-height: 18px;
  margin: 2px auto;
  font-weight: 800;
  color: #3f2d34;
}

.about-me .blue-square {
  width: 8px;
  height: 8px;
  background: #768ac4;
  display: inline-block;
  margin: 0 4px;
}

.highlight {
  display: inline-block;
  height: 100%;
  word-wrap: break-word;
}

.h-blue {
  background: #b6b0c4;
}

.h-purple {
  background: #b080bd;
}

.h-green {
  background: #d4de8f;
}

.chevron-tab {
  content: "";
  width: 43px;
  height: 54px;
  background: #f7939b;
  position: absolute;
  left: 34px;
  top: -34px;
  z-index: -1;
  border-radius: 3px;
  overflow: hidden;
}

.green-tab {
  content: "";
  width: 32px;
  height: 54px;
  background: #a4b766;
  position: absolute;
  left: 174px;
  top: -40px;
  z-index: -1;
  border-radius: 3px;
  overflow: hidden;
}

.stripe-tab {
  content: "";
  width: 65px;
  height: 80px;
  background: white;
  position: absolute;
  left: 93px;
  top: -26px;
  z-index: 1;
  border-radius: 3px;
  overflow: hidden;
}

.stripe-container {
  width: 300%;
  height: 130%;
  transform: rotate(-45deg) translate(-0%, -52%);
  display: flex;
  flex-direction: column;
}

.stripe-pink {
  display: block;
  background: #de6583;
  height: 50px;
  width: 100%;
}

.stripe-peach {
  display: block;
  background: #f5b1a9;
  height: 50px;
  width: 100%;
}

.page2 {
  width: 50%;
  height: 100%;
  background: #e2d6e3;
  margin-left: 10px;
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 22px;
}

.page2:before {
  content: "";
  width: 95%;
  height: 6px;
  position: absolute;
  top: -6px;
  right: 12px;
  background: #b49993;
  z-index: -1;
}
.page2:after {
  content: "";
  width: 95%;
  height: 6px;
  position: absolute;
  bottom: -6px;
  right: 12px;
  background: #b49993;
  z-index: -1;
}

.purple-tab {
  content: "";
  width: 38px;
  height: 38px;
  background: #945aa4;
  position: absolute;
  right: 14px;
  top: -36px;
  z-index: -1;
  border-radius: 3px;
  overflow: hidden;
}

.text-columns {
  width: 100%;
  height: calc(45% - 16px);
  padding-top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-column {
  width: 50%;
  height: 100%;
  background: transparent;
  margin: 0 16px;
}

.title {
  width: 50%;
  border: 2px solid #5b3551;
  margin: 16px 0;
  border-radius: 3px;
}

.info-grid {
  width: 100%;
  height: 55%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
}

.grid {
  width: calc(50% - 8px);
  height: calc(50% - 8px);
  margin-bottom: 8px;
  border-radius: 3px;
}
.gr1 {
  background: #9bc87e;
  margin-right: 8px;
}
.gr2 {
  background: #b080bd;
  width: 50%;
}
.gr3 {
  background: #f2be65;
  width: calc(30% - 8px);
  margin-right: 8px;
}
.gr4 {
  background: #8fa0d0;
  width: 70%;
}

.spiral {
  width: 60px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 999;
  padding: 70px 0;
}

.spiral .ring {
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background: #4b203d;
  position: relative;
}

.spiral .ring:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #b49993;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.spiral .ring:after {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #b49993;
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: -1;
}
