@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap');

.home{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: hidden;
}



button{
    height: 50px;
    border: 3px solid #A8A4FE;
    background: #A8A4FE;
    color: white;
    font-family: 'Zilap';
    box-shadow: 0px 2px 0px 0px #7E7BBF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

button:hover{
    transform: translateY(-10px);
    box-shadow: 0px 14px 0px 0px #7E7BBF;
}
button:active{
    transform: translateY(-6px);
    box-shadow: 0px 6px 0px 0px #7E7BBF;
}


a{
    height: 50px;
    background: #A8A4FE;
    color: white;
    font-family: 'Zilap';
    cursor: pointer;
}



@media screen and (max-width: 500px) {
}

@keyframes hover {
    0%{transform: translateY(0);}
    33%{transform: translateY(-50px);}
    66%{transform: translateY(50px);}
    0%{transform: translateY(0);}
}