
.nav{
    position: fixed;
    left: 50%;
    top: 50px;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    justify-content: space-evenly;
    background: #D5A4FE;
    border-radius: 30px;
}

.nav button{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 20px;
    margin: 4px;
    padding: 2px 0 0;
    background: transparent;
    z-index: 10000;
    cursor: pointer;
    font-family: 'Zilap';
    color: white;
    transform: translateY(0);
    box-shadow: 0 0 0 0 #000000;
}
.nav button:hover{
    transform: translateY(0);
}

.nav-highlight{
    content: '';
    width: 100px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #A8A4FE;
    border-radius: 20px;
    transition: all .25s ease;
}