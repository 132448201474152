@font-face {
  font-family: "Zilap";
  src: local("Zilap"), url("./fonts/ZilapMinimalist.ttf") format("truetype");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: hidden;
  font-family: "Lato", sans-serif;
}

:root {
  --red: #dd5e89;
  --orange: #ddb25e;
  --yellow: #c9dd5e;
  --green: #5edd73;
  --blue: #5ec8dd;
  --purple: #b25edd;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

.container {
  position: absolute;
  left: 0;
  width: 300vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  transition: all 0.25s ease-in-out;
  background: #dd5e89;
  background: -webkit-linear-gradient(to right, #f7bb97, #dd5e89);
  background: linear-gradient(to right, #f7bb97, #dd5e89);
}

.butterfly {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  font-size: 5rem;
  font-weight: 400;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -10px;
  color: white;
  animation: flap-around 45s linear infinite;
}

.splash {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.bubble {
  position: absolute;
  left: calc(25% - 350px);
  bottom: 15%;
  z-index: -1;
  transition: all 0.25s ease;
  animation: hover 45s ease-in-out infinite;
}
.b1 {
  font-size: 50rem;
  left: calc(35% - 350px);
  animation: hover 45s ease-in-out infinite;
}
.b2 {
  font-size: 30rem;
  left: calc(35% + 50px);
  animation: hover 25s ease-in-out infinite;
}
.b3 {
  font-size: 30rem;
  left: calc(85% - 50px);
  bottom: 80%;
  animation: hover 34s ease-in-out infinite;
}
.b4 {
  font-size: 45rem;
  left: 60%;
  bottom: -25%;
  animation: hover 50s ease-in-out infinite;
}
.b5 {
  font-size: 25rem;
  left: 1%;
  bottom: 0%;
  animation: hover 25s ease-in-out infinite;
}

.wing {
  display: block;
  animation: flap 1s ease-in-out infinite;
  font-size: 6rem;
}
.left-wing {
  transform-origin: calc(100% + 6px);
}
.right-wing {
  transform-origin: 4px;
}

.loading {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  animation: loading 2s linear infinite;
  border: 3px solid var(--red);
  position: relative;
  margin-top: -4px;
}
.loading-complete {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  animation: loading-complete 2s linear infinite;
  position: relative;
}
.checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  animation: reveal-check 2s ease 1;
  stroke-dasharray: 25;
  stroke-dashoffset: 50;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
    border: 3px solid var(--red);
    border-left-color: transparent;
    color: var(--red);
  }
  10% {
    transform: rotate(36deg);
  }
  20% {
    transform: rotate(72deg);
    border: 3px solid var(--orange);
    border-left-color: transparent;
    color: var(--orange);
  }
  30% {
    transform: rotate(108deg);
  }
  40% {
    transform: rotate(144deg);
    border: 3px solid var(--yellow);
    border-left-color: transparent;
    color: var(--yellow);
  }
  50% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(216deg);
    border: 3px solid var(--green);
    border-left-color: transparent;
    color: var(--green);
  }
  70% {
    transform: rotate(252deg);
  }
  80% {
    transform: rotate(288deg);
    border: 3px solid var(--blue);
    border-left-color: transparent;
    color: var(--blue);
  }
  90% {
    transform: rotate(324deg);
  }
  100% {
    transform: rotate(360deg);
    border: 3px solid var(--red);
    border-left-color: transparent;
    color: var(--red);
  }
}

@keyframes loading-complete {
  0% {
    transform: rotate(0deg);
    border: 3px solid var(--red);
    color: var(--red);
  }
  10% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
    border: 3px solid var(--orange);
    color: var(--orange);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg);
    border: 3px solid var(--yellow);
    color: var(--yellow);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(0deg);
    border: 3px solid var(--green);
    color: var(--green);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
    border: 3px solid var(--blue);
    color: var(--blue);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
    border: 3px solid var(--red);
    color: var(--red);
  }
}

@keyframes reveal-check {
  0% {
    stroke-dashoffset: 25;
  }
  50% {
    stroke-dashoffset: 50;
  }
  100% {
    stroke-dashoffset: 50;
  }
}

@media screen and (max-width: 768px) {
  .b1 {
    font-size: 40rem;
    left: calc(35% - 350px);
    animation: hover 45s ease-in-out infinite;
  }
  .b2 {
    font-size: 20rem;
    left: calc(35% + 50px);
    animation: hover 25s ease-in-out infinite;
  }
  .b3 {
    font-size: 20rem;
    left: calc(85% - 50px);
    bottom: 80%;
    animation: hover 34s ease-in-out infinite;
  }
  .b4 {
    font-size: 35rem;
    left: 60%;
    bottom: -25%;
    animation: hover 50s ease-in-out infinite;
  }
  .b5 {
    font-size: 15rem;
    left: 1%;
    bottom: 0%;
    animation: hover 25s ease-in-out infinite;
  }
}

@keyframes flap {
  0% {
    transform: rotate3d(0, 1, 0, 0turn);
  }
  50% {
    transform: rotate3d(0, 1, 0, -0.2turn);
  }
  100% {
    transform: rotate3d(0, 1, 0, 0turn);
  }
}

@keyframes flap-around {
  0% {
    transform: translate(-1500%, 500%) rotate(60deg);
  }
  17% {
    transform: translate(-90%, 30%) rotate(30deg);
  }
  23% {
    transform: translate(-90%, 30%) rotate(30deg);
  }
  40% {
    transform: translate(1500%, -500%) rotate(90deg);
  }
  45% {
    transform: translate(1900%, -250%) rotate(140deg);
  }
  47.5% {
    transform: translate(2200%, -125%) rotate(165deg);
  }
  50% {
    transform: translate(2300%, 0%) rotate(190deg);
  }
  52.5% {
    transform: translate(2200%, 125%) rotate(215deg);
  }
  55% {
    transform: translate(1900%, 250%) rotate(240deg);
  }
  60% {
    transform: translate(1500%, 500%) rotate(300deg);
  }
  80% {
    transform: translate(60%, -60%) rotate(295deg);
  }
  100% {
    transform: translate(-1500%, -500%) rotate(290deg);
  }
}
