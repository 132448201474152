.contact{
    position: relative;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 100px 250px;
    transition: all .25s ease;
}

form{
    width: 320px;
    margin: 10px auto;
    border-top: 2px solid white;
    padding-top: 32px;
}

.input{
    margin-bottom: 30px;
}

input, textarea, button{
    width: 320px;
    height: 30px;
    border: 3px solid #A8A4FE;
    border-radius: 3px;
    padding: 6px 6px 2px;
    margin: 0 auto;
    position: relative;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    letter-spacing: 1px;
    resize: none;
    overflow: hidden;
    transition: all .125s ease;
}

button{
    height: 50px;
}

textarea{
    height: 100px;
}

input:focus, textarea:focus{
    outline: 1px solid #F9FE6E;
}
label{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 8px 2px;
    width: auto;
    height: 30px;
    transform: translateY(-30px);
    color: black;
    cursor: text;
    transition: all .125s linear;
    font-family: 'Zilap';
}
textarea + label {
    height: 30px;
    transform: translateY(-105px);
}

input:focus{
    outline: 1px solid #F9FE6E;
}
input:focus + label{
    transform: translateY(calc(-100% - 25px));
    font-size: .8rem;
}
textarea:focus + label{
    transform: translateY(calc(-345% - 25px));
    font-size: .8rem;
}

input + label.stick{
    transform: translateY(calc(-100% - 25px));
    font-size: .8rem;
}

textarea + label.stick{
    transform: translateY(calc(-345% - 25px));
    font-size: .8rem;
}

.green{
    color: #6EFEBB;
}
.red{
    color: #FE736E;
}

.green-border{
    border: 3px solid #60DEA4;
}
.red-border{
    border: 3px solid #DE6560;
}

.message-sent{
    font-family: 'Zilap';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.message-sent p{
    font-family: 'Lato', sans-serif;
}




button:disabled{
    border: 3px solid gray;
    background: gray;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgb(58, 58, 58);
}
button:disabled:hover{
    transform: translateY(0);
    color: white;
    box-shadow: 0 0 0 0 rgb(58, 58, 58);
}

.info{
    width: 320px;
    font-size: 18px;
    font-weight: 900;
    text-align: justify;
}

.info a{
    min-width: 320px;
    text-decoration: none;
    padding: 8px 4px;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .125s ease;
    margin: 32px 0;
}

.info h1{
    min-width: 320px;
    font-size: 4rem;
    font-family: 'Zilap';
}

.info p{
    min-width: 320px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    padding: 12px 0;
}

.discordIcon{
    width: 18px;
    height: 18px;
    margin-right: 4px;
}
a:hover{
    transform: translateY(-10px);
}
a:active{
    transform: translateY(-6px);
}

.discord{
    background: #5865f2;
}
.discord:hover{
    box-shadow: 0px 14px 0px 0px #23272a;
}
.discord:active{
    box-shadow: 0px 6px 0px 0px #23272a;
}

.tiktok{
    background: #fe2c55;
}
.tiktok:hover {
    box-shadow: 0px 14px 0px 0px #BF2140;
}
.tiktok:active{
    box-shadow: 0px 6px 0px 0px #BF2140;
}

.twitch{
    background: #9147ff;
}
.twitch:hover {
    box-shadow: 0px 14px 0px 0px #772ce8;
}
.twitch:active{
    box-shadow: 0px 6px 0px 0px #772ce8;
}

@media screen and (max-width: 1200px) {
    .contact{
        padding: 100px 0px;
    }
}

@media screen and (max-width: 700px) {
    
}